import React from 'react';

// import pnLogo from '../images/pn.svg';

interface ILogo3D {
  fillColor?: string;
  fillOpacity?: number;
  primaryColor?: string;
  secondaryColor?: string;
  strokeWidth?: number;
  borderStrokeWidth?: number;
  width?: number;
  height?: number;
  p?: {
    line: string;
    bg: string
  };
  n?: {
    line: string;
    bg: string
  };
}

const defaultColors = {
  fillColor: '#000',
  primaryColor: '#e45d0f',
  secondaryColor: '#022ac7'
}

export const Logo3D: React.FC<ILogo3D> = ({
  fillColor,
  fillOpacity = 0.2,
  primaryColor = defaultColors.primaryColor,
  secondaryColor = defaultColors.secondaryColor,
  strokeWidth = .6,
  borderStrokeWidth,
  p = {
    line: defaultColors.primaryColor,
    bg: defaultColors.primaryColor
  },
  n = {
    line: defaultColors.secondaryColor,
    bg: defaultColors.secondaryColor
  },
  width = "80%",
  height = "80%",
  ...rest
}) => {
  return (
    <svg
      width={width || "145"}
      height={height || "155"}
      viewBox="0 0 38.364583 41.010417"
      id="svg5"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <defs>
        <filter id="f1" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceGraphic" stdDeviation="20" edgeMode="" />
        </filter>
      </defs>
      <g id="layer1">
        <path
          name="n1"
          fill={n.bg || '#000000'}
          fillOpacity={ fillOpacity ?? "0.417913"}
          strokeWidth="0"
          filter="url(#f1)"
          d="m 22.505647,1.3163098 h 7.9375 L 26.480361,15.136188 a 6.9594236,10.760864 29.110911 0 1 -10.359807,9.992625 h -0.443032 z"
        />
        <path
          name="n1"
          fill="none"
          stroke={n.line || '#0000ff'}
          strokeWidth={strokeWidth}
          d="m 21.726591,0.0814895 h 7.9375 L 25.701305,13.901365 A 6.9594236,10.760864 29.110911 0 1 15.341498,23.89399 h -0.443032 z"
        />
        <path
          name="n2"
          fill={n.bg || '#000000'}
          fillOpacity={ fillOpacity ?? "0.417913"}
          strokeWidth="0"
          filter="url(#f1)"
          d="m 32.694844,1.3229165 h 0.662786 a 6.7553576,10.445331 29.11091 0 1 4.493393,9.6996185 L 33.80422,25.135416 H 33.227945 A 6.8356924,10.569547 29.11091 0 1 28.681116,15.32045 Z"
        />
        <path
          name="n2"
          fill="none"
          stroke={n.line || '#0000ff'}
          strokeWidth={strokeWidth}
          d="m 31.769006,0.0810395 h 0.662786 a 6.7553576,10.445331 29.11091 0 1 4.493393,9.6996192 L 32.878382,23.89354 h -0.576275 a 6.8356924,10.569547 29.11091 0 1 -4.546829,-9.814966 z"
          />
        <path
          name="p1"
          fill={p.bg || '#000000'}
          fillOpacity={ fillOpacity ?? "0.417913"}
          // stroke={primaryColor || '#000000'}
          strokeWidth="0"
          filter="url(#f1)"
          d="m 23.649101,13.676944 h 0.692465 v 39.64935 c 0,5.378664 -4.360268,9.738933 -9.738933,9.738933 H 14.463912 V 22.862132 c 0,-5.072839 4.112349,-9.185188 9.185189,-9.185188 z"
          transform="matrix(0.8035815,0,-0.23042323,0.80358129,3.7686183,-9.8441655)"
        />
        <path
          name="p1"
          fill="none"
          stroke={p.line || '#ff6600'}
          strokeWidth={strokeWidth}
          d="m 23.649101,13.676944 h 0.692465 v 39.64935 c 0,5.378664 -4.360268,9.738933 -9.738933,9.738933 H 14.463912 V 22.862132 c 0,-5.072839 4.112349,-9.185188 9.185189,-9.185188 z"
          transform="matrix(0.8035815,0,-0.23042323,0.80358129,3.1213138,-10.884229)"
        />
        <path
          name="p2"
          fill={p.bg || '#000000'}
          fillOpacity={ fillOpacity ?? "0.417913"}
          strokeWidth="0"
          filter="url(#f1)"
          width="10.583333"
          height="23.8125"
          x="19.843748"
          y="5.4188833"
          d="m 22.310444,1.1445357 h 0.529259 a 6.8793518,10.637055 29.110911 0 1 4.575869,9.8776543 l -1.22964,4.288266 a 6.7184191,10.388216 29.11091 0 1 -10.001047,9.64658 H 15.48232 Z"
        />
        <path
          name="p2"
          fill="none"
          stroke={p.line || '#0000ff'}
          strokeWidth={strokeWidth}
          width="10.583333"
          height="23.8125"
          x="19.843748"
          y="5.4188833"
          d="m 21.510413,0.0814895 h 0.529259 a 6.8793518,10.637055 29.110911 0 1 4.57587,9.8776544 L 25.385901,14.24741 a 6.7184191,10.388216 29.11091 0 1 -10.001047,9.64658 h -0.702565 z"
        />
      </g>
    </svg>
  );
}
