import React from 'react';

import blueImg from '../assets/images/gradient-blue1.png';

// import l500w from  "../assets/images/gradient-blue1-p-500.png";
// import l800w from  "../assets/images/gradient-blue1-p-800.png";
import l1080w from  "../assets/images/gradient-blue1-p-1080.png";

// import linkImg from '../assets/images/link-icon.svg';

const Link = ({name, url}: {name: string, url: string}, i: number) => (
  <React.Fragment key={i}>
    <a
      href={url}
      className="link-big-social-2"
      style={{
        transform: 'translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)',
        transformStyle: 'preserve-3d',
      }}
      target="_blank"
      rel="noreferrer"
    >
      {name}
    </a>
    <div className="line-cont portfolio" style={{opacity: 0}}></div>
  </React.Fragment>
);

function ResponsiveImage() {
  return (
    <img
      src="image-url-1280.jpg"
      srcSet="image-url-300.jpg 300w, image-url-768.jpg 768w, image-url-1280.jpg 1280w"
      sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
    />
  );
}

const socialLinks= [
  { name: "soundcloud", url: "https://soundcloud.com/olaf-viikna" },
  { name: "instagram", url: "https://www.instagram.com/puunukk" }
]

const Hero: React.FC = () => {
  const [globalMousePos, setGlobalMousePos] = React.useState({x:0,y:0});
  const elementRef = React.useRef<HTMLImageElement>(null);

  
  const handleMouseMove = (event: MouseEvent) => {
    const x = event.pageX - ( window.innerWidth * 0.55 );
    const y = event.pageY - ( window.innerHeight * 0.16 );
    setGlobalMousePos({ x, y });
    if (elementRef.current) {
      // elementRef.current.style.transform = `translate3d(0px, ${globalMousePos.y-newY}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)`;
      elementRef.current.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
    }
  };
  
  React.useEffect(() => {  
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  
  React.useEffect(() => {
    const updateTransform = () => {
      const scrollPos = window.scrollY;
      // calculate the new Y value for the transform based on the scroll position
      let newY = 0;
      if (scrollPos <= 14 * window.innerHeight / 100) {
        newY = -scrollPos * 1.0;
      } else {
        newY = -14 - (scrollPos - 14 * window.innerHeight / 100) * 0.5;
      }
      // console.log(newY)
      // update the transform property of the element using the elementRef
      if (elementRef.current && window.innerWidth < 767) {
        // elementRef.current.style.transform = `translate3d(0px, ${globalMousePos.y-newY}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)`;
        elementRef.current.style.transform = `translate3d(${globalMousePos.x}px, ${globalMousePos.y - (newY * 2) }px, 0px)`;
      }
    };
    window.addEventListener('scroll', updateTransform);
    return () => {
      window.removeEventListener('scroll', updateTransform);
    };
  }, []);
  

  return (
    <div
      data-w-id="004a81d1-1e49-3840-5121-08854227b5c4"
      className="section-inicio wf-section"
    >
        
        <div className="cv-cont-anima-2"></div>
        
        <div className="cv-cont-2" >
          <img
            src={blueImg}
            loading="lazy"
            sizes="(max-width: 991px) 100vw, 85vw"
            alt=""
            className="blue-circle1"
            ref={elementRef}
            style={{
              transform: `translate3d(${globalMousePos.x }px, ${globalMousePos.y }px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)`,
              transformStyle: 'preserve-3d'
            }} />
          <img
            src={l1080w}
            loading="lazy"
            sizes="(max-width: 1080px) 100vw, 85vw"
            alt=""
            className="blue-circle1 1080w"
            ref={elementRef}
            style={{
              transform: `translate3d(${globalMousePos.x }px, ${globalMousePos.y }px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)`,
              transformStyle: 'preserve-3d'
            }} />
          {/*
          <div
            ref={elementRef}
            className="bg-cont" style={{
            willChange: 'transform',
            transform: 'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)',
            transformStyle: 'preserve-3d'
          }}>
          </div>
          */}
          
          <h1 className="finally-h-2">hello stranger, I'm </h1>
          
          <div className="div-block-2">
            <h1 className="puunukk_cv-2">puu</h1>
            <h1 className="puunukk_cv-2 nukk">nukk</h1>
          </div>
          
          <div className="jm-cv-cont">
            <h4 className="heading-2">Currently</h4>
            <div className="jm-title">
              <p className="p-body1-2">
                Web Developer <br/>at University of Tartu.
              </p>
              <p className="p-body1-2">
                Love the craft.<br/>
                Love out of the box ideas.<br/>
                Addicted to coffee and jokes.
              </p>
            </div>
          </div>

          <div className="line-cont"></div>
          
          <h1 className="this-h-2">
            <strong className="bold-text">
              This site<br/>
              is under<br/>
              construction
            </strong>
          </h1>
          
          <div className="jm-title-portfolio">
            <h4 className="heading-2">More me?</h4>
            <p className="p-body1-2">sure, you can find me on</p>
          </div>
          <div className="social-cont">
            {
              socialLinks.map(Link)
            }
          </div>

          {/* <div className="jm-title-portfolio">
            <h4 className="heading-2">My work?</h4>
            <p className="p-body1-2">sure, you are very welcome to see it on</p>
          </div>
          <div className="social-cont">
            {
              socialLinks.map(Link)
            }
          </div> */}


          <div className="jm-title-email">
            <h4 className="heading-2">Sure, let's talk</h4>
            <p className="p-body1-2">This is my e-mail if you want to reach out</p>
          </div>

          <div data-w-id="42ace68e-7844-e8cd-8b42-1be8f775267d" className="social-cont">
            <a
              href="mailto:puunukk@gmail.com"
              data-w-id="42ace68e-7844-e8cd-8b42-1be8f775267e"
              className="link-email"
              style={{
                transform: 'translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)',
                transformStyle: 'preserve-3d',
                
              }}
            >
              puunukk@gmail.com
            </a>
          <div className="line-cont portfolio" style={{opacity: 0}}></div>
        </div>

      </div>

    </div>
  )
};

export default Hero;