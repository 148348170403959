import React from 'react';

import Logo from './Logo';
import NewLogo from './Logo2';
import { Logo3D } from './Logo3d';
import Plogo from '../assets/images/P-512.png';

type HeaderSizes = "small" | "medium" | "large";

const Header: React.FC = () => (
  <header>
    <div
      data-collapse="small"
      data-animation="over-right"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar w-nav"
    >
      
      <div className="header-tags">
        <div className="tag1-2">I build interfaces</div>
        <div className="tag1-2 right">Site under construction</div>
      </div>
      
      <div className="container w-container">
        <div className="tag1-2 lovely">Lovely 2023</div>
        
        <a href="/" className="brand w-nav-brand">
          <div
            data-w-id="004a81d1-1e49-3840-5121-08854227b59d"
            data-is-ix2-target="1"
            className="logo-puunukk"
            data-animation-type="lottie"
            data-src="documents/jm-logo.json"
            data-loop="0"
            data-direction="1"
            data-autoplay="0"
            data-renderer="svg"
            data-default-duration="6.666666666666667"
            data-duration="0"
            data-ix2-initial-state="0"
          >
            {/* <Logo /> */}
            {/* <img src={Plogo} /> */}
            <Logo3D />
          </div>
        </a>
        <div className="line-cont">
          <img src="images/gradient-line.png" loading="lazy" data-w-id="004a81d1-1e49-3840-5121-08854227b5a1" alt="" className="gradient-line"
            style={{
              willChange: 'transform',
              transform: 'translate3d(-20vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)',
              transformStyle: 'preserve-3d'
            }}
          />
        </div>
        
        {/* <div className="menu-button w-nav-button" style={{ userSelect: 'text' }} aria-label="menu" role="button" tabIndex={0} aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
          <div className="lottie-animation-2" data-w-id="004a81d1-1e49-3840-5121-08854227b5a3" data-animation-type="lottie" data-src="https://uploads-ssl.webflow.com/62b4f23b0c3b19b3c0d41fb5/62b4f23b0c3b19751ad41fc5_burger-jm-mobile.json" data-loop="0" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="2" data-duration="0"></div>
        </div>
        <div className="open-menu-jm">
          <div className="cont-open-menu">
            <div className="jm-title-portfolio menu3">
              <h4 className="heading-2 menu">SURE, if you want to check my work</h4>
              <p className="p-body1-2">you are VERY VERY welcome to see it on</p>
            </div>

            <div className="social-cont">
              <div className="active-btn-social"></div>
              <a href="https://www.behance.net/puunukkmora2" target="_blank" className="link-big-social-2" style={{
                transform: 'translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)',
                transformStyle: 'preserve-3d',
                color: 'rgb(153, 136, 126)'
              }}>Behance</a>
              <div className="line-cont portfolio" style={{opacity: 0}}>
                <img src={logo2} loading="lazy" alt="" className="link-social-mobile" />
              </div>
              <a href="https://dribbble.com/onemora" target="_blank" className="link-big-social-2" style={{
                transform: 'translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)',
                transformStyle: 'preserve-3d',
                color: 'rgb(153, 136, 126)'
              }}>dribbble</a>
              <div className="line-cont portfolio" style={{opacity: 0}}>
                <img src={logo2} loading="lazy" alt="" className="link-social-mobile" /></div>
              <a href="https://www.instagram.com/puunukk" target="_blank" className="link-big-social-2" style={{
                transform: 'translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)',
                transformStyle: 'preserve-3d',
                color: 'rgb(153, 136, 126)'
              }}>instagram</a>
              <div className="line-cont portfolio" style={{opacity: 0}}>
                <img src={logo2} loading="lazy" alt="" className="link-social-mobile" />
              </div>
            </div>
            <div className="jm-title-portfolio menu">
              <p className="p-body1-2">or email me if you have a question <br/>or want to start a proyect together to</p>
              <h4 className="heading-2 menu2">
                <a href="mailto:puunukkmora.dg@gmail.com?subject=Hey%20puunukk!" className="link">puunukkmora.dg@gmail.com</a>
              </h4>
            </div>
          </div>
        </div> */}

      </div>

    </div>
  </header>
);

export default Header;